import React, { useEffect, useState } from 'react'
import moment from 'moment'
import Pagination from '../../components/Pagination'
import { RuxDialog, RuxButton } from '@astrouxds/react'
import { getTleHistory } from '../../apis/tleApi'

const TleTable = () => {
    const [tleHistory, setTleHistory] = useState([])
    const [tleDialogData, setTleDialogData] = useState(null)

    const [pageInfo, setPageInfo] = useState({
        page: 0,
        perPage: 20
    })
    const [totalEntries, setTotalEntries] = useState(0)
    
    useEffect(() => {
        const loadTleHistory = async () => {
            try {
                const res = await getTleHistory(pageInfo.page*pageInfo.perPage, pageInfo.perPage, true)
                console.log(res.data)
                setTleHistory(res.data)
                setTotalEntries(res.entries)
            } catch (error) {
                console.error('Error loading TLE history:', error)
            }
        }

        loadTleHistory()

        const interval = setInterval(async () => {
            await loadTleHistory()
        }, 1000);

        return () => clearInterval(interval)
    }, [pageInfo])


    const handleShowDetailClick = (t) => {
        setTleDialogData(t)
    }

    const timeToNow = (time) => {
        const now = new Date();
        const tleTime = new Date(time);
        const isToday = moment(tleTime).utc().format('DD.MM.YYYY') === moment(now).utc().format('DD.MM.YYYY');

        let time1
        if(isToday) {
            time1 = moment(tleTime).fromNow()
        } else {
            time1 = moment(tleTime).utc().format('DD.MM.YYYY')
        }
        const time2 = moment(tleTime).utc().format('HH:mm:ss')

        return (
            <span>
                {time1}
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                    {time2}
                </span>
            </span>
        );
    };

    const tleType = (account) => {
        return (
            <span>
                {account ? "Private" : "General"}
                <br />
                <span style={{ fontSize: '0.8em', color: 'gray' }}>
                    {account ? account.account_name : "No Account"}
                </span>
            </span>
        )
    }

    const tleDetailDialog = (
        <RuxDialog open={tleDialogData !== null} header='TLE Detail'>
            {tleDialogData && <pre style={{fontSize: '0.75em'}}>{tleDialogData.tle}</pre>}
            <div slot='footer' onClick={() => setTleDialogData(null)}>
                <RuxButton secondary style={{ float: 'right' }} id="confirm">
                    Close
                </RuxButton>
            </div>
        </RuxDialog>
    )

    

    return (
        <div>
            <rux-table>
                <rux-table-body>
                    <rux-table-header-row selected="false">
                        <rux-table-header-cell>Title</rux-table-header-cell>
                        <rux-table-header-cell>Time</rux-table-header-cell>
                        <rux-table-header-cell>Type</rux-table-header-cell>
                        <rux-table-header-cell>Detail</rux-table-header-cell>
                    </rux-table-header-row>
                    {tleHistory.map((t, i) => (
                        <rux-table-row key={i} selected="false">
                            <rux-table-cell>{t.title}</rux-table-cell>
                            <rux-table-cell>{timeToNow(t.time)}</rux-table-cell>
                            <rux-table-cell>{tleType(t.account)}</rux-table-cell>
                            <rux-table-cell>
                                <RuxButton secondary icon-only icon="info" borderless onClick={() => handleShowDetailClick(t)}/>
                            </rux-table-cell>
                        </rux-table-row>
                    ))}
                </rux-table-body>
            </rux-table>
            <div style={{height: '50px'}}>
                <Pagination pageInfo={pageInfo} setPageInfo={setPageInfo} totalEntries={totalEntries}/>
            </div>
            {tleDetailDialog}
        </div>
    )
}   

export default TleTable