import React, {useState, useEffect} from 'react';
import {RuxInput, RuxOption, RuxSelect} from '@astrouxds/react';
import * as api from "../../../apis/objectApi";
import { getCommandTemplates } from '../../../apis/commandApi';
import {notify} from "../../../libs/notificationSystem/notificationManager";
import ProfileManagement from "../../profileManagement/ProfileManagement";

const ObjectForm = ({selectedObject, clearSelectedObject}) => {
    const [form, setForm] = useState({
        norad_id: '',
        name: '',
        note: '',
        template_id: null
    });
    const [templates, setTemplates] = useState([])

    useEffect(() => {
        if (selectedObject) {
            const {object_id, norad_id, name, note, template_id} = selectedObject;

            setForm({
                object_id,
                norad_id,
                name,
                note,
                template_id
            });
        }
    }, [selectedObject]);

    useEffect(() => {
        const fetchTemplates = async () => {
            const res = await getCommandTemplates()
            setTemplates(res.map(template => ({
                template_id: template.template_id,
                name: template.name
            })))
        }
        fetchTemplates()
    }, [])


    const handleFormChange = (e) => {
        const {name, value} = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value,
        }));
    };

    const handleSaveClick = async () => {
        try {
            const {object_id, norad_id, name, note, template_id} = form;

            const object = {
                norad_id: parseInt(norad_id) || null,
                object_id: object_id || null,
                name: name || null,
                note: note || null,
                template_id: template_id || null
            };

            let response;
            if (object_id) {
                response = await api.updateObject(object_id, object);
            } else {
                response = await api.createObject(object);
            }

            notify('Object saved', 'normal');

            setForm({
                norad_id: '',
                object_id: '',
                name: '',
                note: '',
                template_id: null
            });
            clearSelectedObject();
        } catch (error) {
            console.error('Error saving object:', error);
            notify('Error saving object.', 'critical');
        }
    };

    const handleCancelClick = () => {
        setForm({
            norad_id: '',
            name: '',
            note: '',
            template_id: null
        });
        clearSelectedObject();
    };

    return (
        <div>
            <p>
                NORAD ID: <RuxInput name="norad_id" onRuxinput={handleFormChange} value={form.norad_id}/>
            </p>
            <p>
                Name: <RuxInput name="name" onRuxinput={handleFormChange} value={form.name}/>
            </p>
            <p>
                Note <RuxInput size="small" name="note" onRuxinput={handleFormChange} value={form.note}/>
            </p>
            <p>
                Template 
                <RuxSelect size="small" name="template_id" onRuxchange={handleFormChange} value={form.template_id}>
                    <RuxOption value={""} label="No template" />
                    { templates.map(template => (
                        <RuxOption key={template.template_id} value={template.template_id} label={template.name} />
                    ))}
                </RuxSelect>
            </p>
            <p>
                {selectedObject && (
                    <ProfileManagement objectId={selectedObject.object_id}/>
                )
                }
            </p>
            <p>
                <rux-button-group h-align="left">
                    <rux-button onClick={handleSaveClick}>Save Object</rux-button>
                    <rux-button secondary="" onClick={handleCancelClick}>Cancel</rux-button>
                </rux-button-group>
            </p>
        </div>
    );
};

export default ObjectForm;
