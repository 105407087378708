import api from './config/axiosConfig'

const TLE_API_BASE_URL = 'tle'

export const getTleHistory = async (offset, limit, sendEntries=false) => {
    let queryString = ''
    const params = new URLSearchParams()
    if (offset) {
        params.append('offset', offset.toString())
    }
    if (limit) {
        params.append('limit', limit.toString())
    }

    if (params.toString()) {
        queryString = `?${params.toString()}`;
    }

    const response = await api.get(`${TLE_API_BASE_URL}${queryString}`)
    return sendEntries ? response : response.data;
}

export const createTleEntry = (data) => {
    return api.post(TLE_API_BASE_URL, data)
}

export const celestrakRefresh = () => {
    return api.get(`${TLE_API_BASE_URL}/refresh`)
}