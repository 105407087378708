import React, {useEffect, useState} from 'react';
import TleTable from '../systems/tleManagement/TleTable'
import TleForm from '../systems/tleManagement/TleForm';
import { useAuth } from '../auth/AuthContext';
import { RuxAccordionItem, RuxButton } from '@astrouxds/react';
import { celestrakRefresh } from '../apis/tleApi';

const TleManagementScreen = () => {
    const { hasPermission } = useAuth()
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    useEffect(() => {
        document.title = "GCN TLE";
    }, []);

    const handleAccordionCollapse = () => {
        setIsAccordionOpen(false)
    };

    const handleCelestrakRefresh = () => {
        celestrakRefresh()
    }

    return (
        
        <div style={{
            display: 'grid',
            gridTemplateColumns: '100%',
            justifyContent: 'space-evenly',
            margin: '12px',
            gap: '12px'
        }}>
            <div style={{ gridColumn: '1' }}>
                <rux-container style={{ margin: '12px 0' }}>
                    <div slot="header">TLE</div>
                    <div style={{ display: 'flex', alignItems: 'space', gap: '1rem' }}>
                        {hasPermission('tle_manage_*') && (
                            <RuxAccordionItem
                                expanded={isAccordionOpen ? true : undefined}
                                onRuxcollapsed={handleAccordionCollapse}
                            >
                                <div slot="label">Add TLE data</div>
                                <TleForm />
                            
                            </RuxAccordionItem>
                        )}
                        <RuxButton secondary onClick={handleCelestrakRefresh}>Refresh Celestrak Data</RuxButton>
                    </div>
                    <br/>
                    {hasPermission('tle_view_*') && <TleTable/>}
                </rux-container>
            </div>
        </div>
    );
}

export default TleManagementScreen