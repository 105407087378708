import React, {useState, useEffect} from 'react';
import {RuxInput, RuxOption, RuxSelect, RuxTextarea} from '@astrouxds/react';
import { createTleEntry } from '../../apis/tleApi';
import { notify } from '../../libs/notificationSystem/notificationManager';

const TleForm = () => {
    const [form, setForm] = useState({
        title: '',
        tle: ''
    })

    const handleFormChange = (e) => {
        const {name, value} = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value
        }));
    }

    const handleSaveClick = async () => {
        try {
            await createTleEntry({
                title: form.title || null,
                tle: form.tle
            })
            setForm({
                title: '',
                tle: ''
            })
            notify('TLE saved', 'normal')
        } catch (error) {
            console.error('Error saving TLE:', error)
            notify('Error saving TLE', 'critical')
        }
    }

    const handleCancelClick = () => {
        setForm({
            title: '',
            tle: ''
        })
    }

    return (
        <div>
            <p>
                Title: <RuxInput name="title" onRuxinput={handleFormChange} value={form.title}/>
            </p>
            <p>
                TLE: <RuxTextarea name="tle" onRuxinput={handleFormChange} value={form.tle}/>
            </p>
            <p>
                <rux-button-group h-align="left">
                    <rux-button onClick={handleSaveClick}>Save TLE</rux-button>
                    <rux-button secondary="" onClick={handleCancelClick}>Cancel</rux-button>
                </rux-button-group>
            </p>
        </div>
    )
}

export default TleForm